import { EnvironmentService } from '../Environment';

class Analytics {
  /**
   * Track user id
   */
  public trackUserIdEvent(event: string | null, userId?: string) {
    try {
      Analytics.trackEvent(event, { userId });
    } catch {
      Analytics.log(event, { userId } || {});
    }
  }

  private static trackEvent(event: string | null, params: object): void {
    const dataLayer = window.dataLayer || [];

    dataLayer.push({
      event,
      params,
    });

    Analytics.log(event, params, 'success');
  }

  private static log(
    event: string | null,
    params: object,
    type: 'fail' | 'success' = 'fail',
  ): void {
    if (!EnvironmentService.withLogs) {
      return void 0;
    }
    const messageType = type === 'fail' ? 'error' : 'info';

    console.groupCollapsed(`Google Analytics event "${event ?? 'noname'}"`);
    console[messageType](params);
    console.groupEnd();
  }
}

export const AnalyticsService = new Analytics();
