import { UserController } from 'controllers/UserController';
import { Ampli } from 'services/Ampli';
import { AnalyticsService } from 'services/AnalyticsService/AnalyticsService';
import { Sentry } from 'services/Sentry';

import * as Types from './types';

export const markUserAsLoggedIn = (): Types.MarkUserAsLoggedInAction => ({
  type: Types.UserActionTypes.MARK_USER_AS_LOGGED_IN,
});

export const validateCsrfTokenStart = (): Types.ValidateCsrfTokenActionStart => ({
  type: Types.UserActionTypes.VALIDATE_CSRF_TOKEN__START,
});

export const validateCsrfTokenSuccess = (): Types.ValidateCsrfTokenActionSuccess => ({
  type: Types.UserActionTypes.VALIDATE_CSRF_TOKEN__SUCCESS,
});

export const validateCsrfTokenFail = (
  error: Types.ValidateCsrfTokenActionFail['error'],
): Types.ValidateCsrfTokenActionFail => ({
  type: Types.UserActionTypes.VALIDATE_CSRF_TOKEN__FAIL,
  error,
});

export const inactivityMonitoringStart = (): Types.InactivityMonitoringActionStart => ({
  type: Types.UserActionTypes.INACTIVITY_MONITORING__START,
});

export const inactivityMonitoringSuccess = (): Types.InactivityMonitoringActionSuccess => ({
  type: Types.UserActionTypes.INACTIVITY_MONITORING__SUCCESS,
});

export const inactivityMonitoringFail = (
  error: Types.InactivityMonitoringActionFail['error'],
): Types.InactivityMonitoringActionFail => ({
  type: Types.UserActionTypes.INACTIVITY_MONITORING__FAIL,
  error,
});

export const checkNewUserEmailAddressStart = (
  payload: Types.CheckNewUserEmailActionStart['payload'],
): Types.CheckNewUserEmailActionStart => ({
  type: Types.UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__START,
  payload,
});

export const checkNewUserEmailAddressSuccess = (
  payload: Types.CheckNewUserEmailActionSuccess['payload'],
): Types.CheckNewUserEmailActionSuccess => ({
  type: Types.UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__SUCCESS,
  payload,
});

export const checkNewUserEmailAddressFail = (
  error: Types.CheckNewUserEmailActionFail['error'],
): Types.CheckNewUserEmailActionFail => ({
  type: Types.UserActionTypes.CHECK_NEW_USER_EMAIL_ADDRESS__FAIL,
  error,
});

export const logInUserStart = (
  payload: Types.LogInUserActionStart['payload'],
): Types.LogInUserActionStart => ({
  type: Types.UserActionTypes.LOG_IN__START,
  payload,
});

export const logInUserSuccess = (
  payload: Types.LogInUserActionSuccess['payload'],
): Types.LogInUserActionSuccess => ({
  type: Types.UserActionTypes.LOG_IN__SUCCESS,
  payload,
});

export const logInUserFail = (
  error: Types.LogInUserActionFail['error'],
): Types.LogInUserActionFail => ({
  type: Types.UserActionTypes.LOG_IN__FAIL,
  error,
});

export const logInAdminAsHomeownerStart = (
  payload: Types.LogInUserAsAdminActionStart['payload'],
): Types.LogInUserAsAdminActionStart => ({
  type: Types.UserActionTypes.LOG_IN_ADMIN_AS_HOMEOWNER__START,
  payload,
});

export const logInAdminAsHomeownerSuccess = (
  payload: Types.LogInUserAsAdminActionSuccess['payload'],
): Types.LogInUserAsAdminActionSuccess => ({
  type: Types.UserActionTypes.LOG_IN_ADMIN_AS_HOMEOWNER__SUCCESS,
  payload,
});

export const logInAdminAsHomeownerFail = (
  error: Types.LogInUserAsAdminActionFail['error'],
): Types.LogInUserAsAdminActionFail => ({
  type: Types.UserActionTypes.LOG_IN_ADMIN_AS_HOMEOWNER__FAIL,
  error,
});

export const logOutUserStart = (unauthorized = false): Types.LogOutUserActionStart => ({
  type: Types.UserActionTypes.LOG_OUT__START,
  payload: { unauthorized },
});

export const logOutUserSuccess = (): Types.LogOutUserActionSuccess => ({
  type: Types.UserActionTypes.LOG_OUT__SUCCESS,
});

export const registerNewHomeownerUserStart = (
  payload: Types.RegisterNewHomeownerUserActionStart['payload'],
): Types.RegisterNewHomeownerUserActionStart => ({
  type: Types.UserActionTypes.REGISTER_NEW_HOMEOWNER__START,
  payload,
});

export const registerNewHomeownerUserSuccess = (): Types.RegisterNewHomeownerUserActionSuccess => ({
  type: Types.UserActionTypes.REGISTER_NEW_HOMEOWNER__SUCCESS,
});

export const registerNewHomeownerUserFail = (
  error: Types.RegisterNewHomeownerUserActionFail['error'],
): Types.RegisterNewHomeownerUserActionFail => ({
  type: Types.UserActionTypes.REGISTER_NEW_HOMEOWNER__FAIL,
  error,
});

export const registerExistingHomeownerUserStart = (
  payload: Types.RegisterExistingHomeownerUserActionStart['payload'],
): Types.RegisterExistingHomeownerUserActionStart => ({
  type: Types.UserActionTypes.REGISTER_EXISTING_HOMEOWNER__START,
  payload,
});

export const registerExistingHomeownerUserSuccess = (
  payload: Types.RegisterExistingHomeownerUserActionSuccess['payload'],
): Types.RegisterExistingHomeownerUserActionSuccess => ({
  type: Types.UserActionTypes.REGISTER_EXISTING_HOMEOWNER__SUCCESS,
  payload,
});

export const registerExistingHomeownerUserFail = (
  error: Types.RegisterExistingHomeownerUserActionFail['error'],
): Types.RegisterExistingHomeownerUserActionFail => ({
  type: Types.UserActionTypes.REGISTER_EXISTING_HOMEOWNER__FAIL,
  error,
});

export const verifyEmailAddressStart = (
  payload: Types.VerifyEmailAddressActionStart['payload'],
): Types.VerifyEmailAddressActionStart => ({
  type: Types.UserActionTypes.VERIFY_EMAIL_ADDRESS__START,
  payload,
});

export const verifyEmailAddressSuccess = (
  payload: Types.VerifyEmailAddressActionSuccess['payload'],
): Types.VerifyEmailAddressActionSuccess => ({
  type: Types.UserActionTypes.VERIFY_EMAIL_ADDRESS__SUCCESS,
  payload,
});

export const verifyEmailAddressFail = (
  error: Types.VerifyEmailAddressActionFail['error'],
): Types.VerifyEmailAddressActionFail => ({
  type: Types.UserActionTypes.VERIFY_EMAIL_ADDRESS__FAIL,
  error,
});

export const resendVerificationEmailStart = (
  payload: Types.ResendVerificationEmailActionStart['payload'],
): Types.ResendVerificationEmailActionStart => ({
  type: Types.UserActionTypes.RESEND_VERIFICATION_EMAIL__START,
  apiRequest: UserController.resendVerificationEmail,
  payload,
  followupActions: {
    SUCCESS: resendVerificationEmailSuccess,
    FAIL: resendVerificationEmailFail,
  },
});

export const resendVerificationEmailSuccess = (
  payload: Types.ResendVerificationEmailActionSuccess['payload'],
): Types.ResendVerificationEmailActionSuccess => ({
  type: Types.UserActionTypes.RESEND_VERIFICATION_EMAIL__SUCCESS,
  payload,
});

export const resendVerificationEmailFail = (
  error: Types.ResendVerificationEmailActionFail['error'],
): Types.ResendVerificationEmailActionFail => ({
  type: Types.UserActionTypes.RESEND_VERIFICATION_EMAIL__FAIL,
  error,
});

export const fetchAccountInfoStart = (): Types.FetchAccountInfoActionStart => ({
  type: Types.UserActionTypes.FETCH_ACCOUNT_INFO__START,
  apiRequest: UserController.fetchAccountInfo,
  followupActions: { SUCCESS: fetchAccountInfoSuccess, FAIL: fetchAccountInfoFail },
});

export const fetchAccountInfoSuccess = (
  payload: Types.FetchAccountInfoActionSuccess['payload'],
): Types.FetchAccountInfoActionSuccess => {
  const { homeOwnerInfo } = payload.data.data;

  Sentry.setUser(homeOwnerInfo);
  Ampli.setUser(homeOwnerInfo);
  AnalyticsService.trackUserIdEvent('track_user_id_event', homeOwnerInfo.id);

  return { type: Types.UserActionTypes.FETCH_ACCOUNT_INFO__SUCCESS, payload };
};

export const fetchAccountInfoFail = (
  error: Types.FetchAccountInfoActionFail['error'],
): Types.FetchAccountInfoActionFail => ({
  type: Types.UserActionTypes.FETCH_ACCOUNT_INFO__FAIL,
  error,
});

export const requestPasswordResetEmailStart = (
  payload: Types.RequestPasswordResetEmailActionStart['payload'],
): Types.RequestPasswordResetEmailActionStart => ({
  type: Types.UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__START,
  apiRequest: UserController.sendPasswordResetLink,
  payload,
  followupActions: {
    SUCCESS: requestPasswordResetEmailSuccess,
    FAIL: requestPasswordResetEmailFail,
  },
});

export const requestPasswordResetEmailSuccess = (
  payload: Types.RequestPasswordResetEmailActionSuccess['payload'],
): Types.RequestPasswordResetEmailActionSuccess => ({
  type: Types.UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__SUCCESS,
  payload,
});

export const requestPasswordResetEmailFail = (
  error: Types.RequestPasswordResetEmailActionFail['error'],
): Types.RequestPasswordResetEmailActionFail => ({
  type: Types.UserActionTypes.REQUEST_PASSWORD_RESET_EMAIL__FAIL,
  error,
});

export const verifyPasswordResetLinkStart = (
  payload: Types.VerifyPasswordResetLinkActionStart['payload'],
): Types.VerifyPasswordResetLinkActionStart => ({
  type: Types.UserActionTypes.VERIFY_PASSWORD_RESET_LINK__START,
  apiRequest: UserController.verifyResetPasswordLink,
  payload,
  followupActions: {
    SUCCESS: verifyPasswordResetLinkSuccess,
    FAIL: verifyPasswordResetLinkFail,
  },
});

export const verifyPasswordResetLinkSuccess = (
  payload: Types.VerifyPasswordResetLinkActionSuccess['payload'],
): Types.VerifyPasswordResetLinkActionSuccess => ({
  type: Types.UserActionTypes.VERIFY_PASSWORD_RESET_LINK__SUCCESS,
  payload,
});

export const verifyPasswordResetLinkFail = (
  error: Types.VerifyPasswordResetLinkActionFail['error'],
): Types.VerifyPasswordResetLinkActionFail => ({
  type: Types.UserActionTypes.VERIFY_PASSWORD_RESET_LINK__FAIL,
  error,
});

export const changePasswordStart = (
  payload: Types.ChangePasswordActionStart['payload'],
): Types.ChangePasswordActionStart => ({
  type: Types.UserActionTypes.CHANGE_PASSWORD__START,
  apiRequest: UserController.changePassword,
  payload,
  followupActions: {
    SUCCESS: changePasswordSuccess,
    FAIL: changePasswordFail,
  },
});

export const changePasswordSuccess = (
  payload: Types.ChangePasswordActionSuccess['payload'],
): Types.ChangePasswordActionSuccess => ({
  type: Types.UserActionTypes.CHANGE_PASSWORD__SUCCESS,
  payload,
});

export const changePasswordFail = (
  error: Types.ChangePasswordActionFail['error'],
): Types.ChangePasswordActionFail => ({
  type: Types.UserActionTypes.CHANGE_PASSWORD__FAIL,
  error,
});
